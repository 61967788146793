import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useNotesPermissions = function (user) {
    var hasPermissionListNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTE, MethodCode.LIST);
    var hasPermissionEditNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTE, MethodCode.EDIT);
    var hasPermissionViewNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTE, MethodCode.VIEW);
    var hasPermissionDeleteNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTE, MethodCode.DELETE);
    var hasPermissionCreateNote = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.NOTE, MethodCode.CREATE);
    return {
        hasPermissionListNote: hasPermissionListNote,
        hasPermissionEditNote: hasPermissionEditNote,
        hasPermissionViewNote: hasPermissionViewNote,
        hasPermissionDeleteNote: hasPermissionDeleteNote,
        hasPermissionCreateNote: hasPermissionCreateNote,
    };
};
export default useNotesPermissions;
